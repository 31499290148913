import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { navigate } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';
import imgTelegram from '../assets/images/blog/subribe.svg';

const EMPTY_SUBSCRIPTION_DATA = {
  subscriberName: '',
  subscriberEmail: '',
};

const SubscriptionForm = () => {
  const intl = useIntl();
  const [formData, setFormData] = useState(EMPTY_SUBSCRIPTION_DATA);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    await fetch('/api/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...formData,
        userFromGoogleAds: window?.userFromGoogleAds,
        userFromFacebookAds: window?.userFromFacebookAds,
      }),
    });

    const MailchimpData = {
      FNAME: formData.subscriberName,
      LNAME: '',
      MMERGE4: new Date().toISOString().split('T')[0], // contact date
      MMERGE17: 'Subscribe form', // Source
    };
    addToMailchimp(formData.subscriberEmail, MailchimpData);

    setFormData(EMPTY_SUBSCRIPTION_DATA);

    navigate('/newsletter/thank-you');
  };

  const namePlaceholder = intl.formatMessage({ id: 'sform.name.placeholder' }) || 'Your name';
  const emailPlaceholder = intl.formatMessage({ id: 'sform.email.placeholder' }) || 'Your email';

  return (
    <div className="subscription">
      <img src={imgTelegram} alt="imgTelegram" className="subscription__img" />
      <div className="subscription__text-wrapper">
        <img src={imgTelegram} alt="imgTelegram" className="subscription__img hidden" />
        <h2 className="subscription__title">
          <FormattedMessage id="sform.title" />
        </h2>
        <p className="subscription__description">
          <FormattedMessage id="sform.description" />
        </p>
      </div>

      <form className="subscription__form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="subscriberName"
          placeholder={namePlaceholder}
          className="subscription__input"
          required
          value={formData.subscriberName}
          onChange={handleInputChange}
        />
        <input
          type="mail"
          name="subscriberEmail"
          placeholder={emailPlaceholder}
          className="subscription__input"
          required
          value={formData.subscriberEmail}
          onChange={handleInputChange}
        />
        <button type="submit" className="subscription__btn c-btn c-btn--red">
          <FormattedMessage id="sform.signUpNow" />
          <IconArrowRight />
        </button>
      </form>
    </div>
  );
};

export default SubscriptionForm;
