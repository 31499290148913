import React from 'react';
import { Link, graphql } from 'gatsby';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  WhatsappShareButton,
} from 'react-share';

import Layout from '../components/Layout';
import SubscriptionForm from '../components/SubscriptionForm';

import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';
import iconFB from '../assets/images/blog/blog-inner-page/facebook-logo-2.svg';
import iconTW from '../assets/images/blog/blog-inner-page/twitter-2.svg';
import iconPIN from '../assets/images/blog/blog-inner-page/pinterest.svg';
import iconWA from '../assets/images/blog/blog-inner-page/whatsapp.svg';

const BlogInnerPage = ({ data }) => {
  const popularPosts = data.popularPosts.edges;
  const postData = data.prismicArticle.data;
  const similarPosts = data.similarPosts.edges;

  const postURL = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <Layout
      title={postData?.meta_title?.text || postData?.title?.text}
      description={
        postData?.meta_description?.text || `${postData?.content?.text.slice(0, 157)}...`
      }
      metaImage={postData?.image?.url}
    >
      <div className="blog-page blog-page--inner">
        <div className="inner__header">
          <div className="container">
            <div className="head">
              <div className="head__content">
                <h1 className="title">{postData?.title?.text}</h1>
                <div className="content__author">
                  <p className="wrapper">
                    {postData?.author?.document?.data?.photo?.url && (
                      <img
                        src={postData.author.document.data.photo.url}
                        alt="author"
                        className="author"
                      />
                    )}
                    <span className="author__name">
                      {postData?.author?.document?.data?.name?.text}
                    </span>{' '}
                    • <span className="date">{postData?.date}</span>
                  </p>
                  <div className="socials">
                    <p className="c-text-16 text-fw-600">Share:</p>
                    <FacebookShareButton url={postURL}>
                      <div className="socials__item">
                        <img src={iconFB} alt="iconFB" />
                      </div>
                    </FacebookShareButton>
                    <TwitterShareButton url={postURL}>
                      <div className="socials__item">
                        <img src={iconTW} alt="iconTW" />
                      </div>
                    </TwitterShareButton>
                    <PinterestShareButton url={postURL}>
                      <div className="socials__item">
                        <img src={iconPIN} alt="iconPIN" />
                      </div>
                    </PinterestShareButton>
                    <WhatsappShareButton url={postURL}>
                      <div className="socials__item">
                        <img src={iconWA} alt="iconWA" />
                      </div>
                    </WhatsappShareButton>
                  </div>
                </div>
              </div>
              {postData?.image && (
                <img src={postData.image.url} alt="imgPost" className="head__img" />
              )}
            </div>
          </div>
        </div>
        <div className="blog__main inner">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-8 order-1 order-lg-0">
                {postData?.content?.html && (
                  <div
                    className="post-inner"
                    dangerouslySetInnerHTML={{ __html: postData.content.html }}
                  />
                )}
              </div>
              <div className="col-12 col-md-12 col-lg-4 order-last">
                <div className="block_r">
                  <h3 className="block_r__title">Popular Posts</h3>
                  <div className="popular-posts">
                    {popularPosts.map(({ node }, index) => (
                      <Link to={`/blog/${node.uid}`} className="post" key={index}>
                        <div className="post__content">
                          <h5 className="post__title">{node?.data?.title?.text}</h5>
                          <p className="post__author">
                            {node?.data?.author?.document?.data?.name?.text} • {node?.data?.date}
                          </p>
                        </div>
                        {node?.data?.image && (
                          <img src={node.data.image.url} alt="imgPost" className="post__img" />
                        )}
                      </Link>
                    ))}
                  </div>
                  <SubscriptionForm />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="inner-bottom">
          <div className="container">
            <h2 className="title">You might also like</h2>
            <div className="row">
              {similarPosts.map(({ node }, index) => (
                <div className="col-lg-4 col-sm-6 col-12" key={index}>
                  <Link to={`/blog/${node.uid}`} className="post" key={index}>
                    {node?.data?.image && (
                      <img src={node.data.image.url} alt="imgPost" className="post__img" />
                    )}
                    <div className="post__author">
                      {node?.data?.author?.document?.data?.photo?.url && (
                        <img
                          src={node.data.author.document.data.photo.url}
                          alt="author"
                          className="author"
                        />
                      )}
                      <p>
                        <span className="author__name">
                          {node?.data?.author?.document?.data?.name?.text}
                        </span>{' '}
                        • <span className="date">{node?.data?.date}</span>
                      </p>
                    </div>
                    <div className="post__content">
                      <h3 className="content__title">{node?.data?.title?.text}</h3>
                      <p className="content__description">
                        {node?.data?.content?.text.slice(0, 250)}...
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <section className="seventh-section">
          <div className="container">
            <h2 className="c-title-38">
              Ready To Start Your Overseas <span className="colored">Adventure?</span>
            </h2>
            <Link to="/apply/" className="c-btn c-btn--red">
              Let's Go
              <IconArrowRight />
            </Link>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Article($documentId: String!) {
    popularPosts: allPrismicArticle(
      filter: { data: { popular_post: { eq: true } } }
      limit: 4
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            date(formatString: "MMMM, DD YYYY")
            image {
              url
            }
            author {
              document {
                ... on PrismicTeam {
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    similarPosts: allPrismicArticle(
      filter: { id: { ne: $documentId } }
      limit: 3
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            content {
              text
            }
            date(formatString: "MMMM, DD YYYY")
            image {
              url
            }
            author {
              document {
                ... on PrismicTeam {
                  data {
                    name {
                      text
                    }
                    photo {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    prismicArticle(id: { eq: $documentId }) {
      data {
        title {
          text
        }
        content {
          html
          text
        }
        image {
          url
        }
        date(formatString: "MMMM, DD YYYY")
        author {
          document {
            ... on PrismicTeam {
              id
              data {
                name {
                  text
                }
                photo {
                  url
                }
              }
            }
          }
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }
  }
`;

export default BlogInnerPage;
